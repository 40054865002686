@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Bootzy TM Regular';
  src: url('/public/fonts/Bootzy-TM-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Bootzy Condensed TM Regular';
  src: url('/public/fonts/Bootzy-Condensed-TM-Regular.otf');
  font-weight: normal;
  font-style: normal;
}


body {
  margin: 0;
  background-color: #0f0f0f;
  font-family: 'Bootzy TM Regular', sans-serif !important;
  letter-spacing: 1px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes fadeInBackground {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.bg-fade-in::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  opacity: 0; /* Empieza invisible */
  animation: fadeInBackground 2s ease-in-out forwards;
  z-index: -1; /* Mantiene el pseudo-elemento detrás del contenido */
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
